<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    :active.sync="rezoningModal"
    :title="''"
    :buttons-hidden="true">
    <div class="-mt-4">
      <div>
        <p class="mb-3">
          <span class="font-bold text-darkgray">{{$t('Order Number: ')}}</span> 
          <span class="font-bold text-primary">{{rowData.tracking_number}}</span>
        </p>
        <p class="mb-3">
          <span class="font-bold text-darkgray">{{$t('Feedback: ')}}</span> 
          <span class="font-bold text-black"> {{$t('Unzoned')}}</span>
        </p>
        <p class="mb-3">
          <span class="font-bold text-darkgray">{{$t('Address')}}:</span>
          <span class="font-bold text-black"> {{ rowData.customer.address.line_1 }} | {{ rowData.customer.address.line_2 }} </span>
        </p>
        <div class="flex sm:flex-row flex-col gap-3">
          <v-select class="w-full" v-on:input="orderUpdated.governorate ? loadCities(`${orderUpdated.governorate.id}`) : ''" name="governorate" v-model="orderUpdated.governorate" :placeholder="$t('Governorate')" label="name" :options="governorates"/>
          <v-select class="w-full" v-on:input="orderUpdated.city ? loadZones(`${orderUpdated.city.id}`) : ''" name="city" v-model="orderUpdated.city" :placeholder="$t('City')" label="name" :options="cities"/>
          <v-select class="w-full" name="zone" v-model="orderUpdated.zone" :placeholder="$t('Zonr')" label="name" :options="zones"/>
        </div>
        <div class="mt-6 text-right">
        <vs-button @click="rezoningOrder()" class="m-auto">{{$t('Rezoning')}}</vs-button>
      </div>
      </div>
    </div>
  </shipblu-prompt>
</template>

<script>
import common from '../../../assets/utils/common'
import { sendRequest } from '../../../http/axios/requestHelper'
import i18nData from '../../../i18n/i18nData'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'

export default {
  props: ['rezoningModal', 'rowData'],
  data () {
    return {
      governorates: [],
      cities: [],
      zones: [],
      orderUpdated: {}
    }
  },
  watch: {
    rezoningModal (val) {
      if (val === true) {
        this.orderUpdated = {
          zone: this.rowData.customer.address.zone,
          city: this.rowData.customer.address.zone.city,
          governorate: this.rowData.customer.address.zone.city.governorate
        }
        this.loadGovernorates()
      }
    }
  },
  methods: {
    loadGovernorates () {
      const govPromise = common.loadGovernorates(this)
      govPromise.then((results) => {
        this.governorates = results.data
      })
    },
    loadCities (governorateID) {
      const cityPromise = common.loadCities(governorateID, this)
      cityPromise.then((results) => {
        this.cities = results.data
      })
    },
    loadZones (cityID) {
      const zonePromise = common.loadZones(cityID, this)
      zonePromise.then((results) => {
        this.zones = results.data.filter(item => item.id !== 284)
      })
    },
    rezoningOrder () {
      const address = {
        customer: {
          id: this.rowData.customer.id,
          address: {
            id: this.rowData.customer.address.id,
            zone: this.orderUpdated.zone.id
          }
        }
      }
      sendRequest(false, false, this, `api/v1/delivery-orders/${this.rowData.id}/`, 'patch', address, true,
        () => {
          this.$vs.notify({
            color:'success',
            title: i18nData[this.$i18n.locale]['Success'],
            text: i18nData[this.$i18n.locale]['Delivery Order'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.$emit('rezoningModal', false)
          this.$emit('loadData')
        }
      )
    },
    closeModal () {
      this.$emit('rezoningModal', false)
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  }
}
</script>