<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="actionModal"
      :title="''"
      :buttons-hidden="true">
      <div class="mt-4">
        <div>
          <p class="mb-3">
            <span class="font-bold text-darkgray">{{$t('Order Number: ')}}</span> 
            <span class="font-bold text-primary">{{rowData.tracking_number}}</span>
          </p>
          <p>
            <span class="font-bold text-darkgray">{{$t('Feedback: ')}}</span> 
          </p>
          <div class="mt-4" v-if="actionType === 'deliver_again'">
            <p class="font-semibold">{{$t('By choosing "Deliver Again", you acknowledge that you have communicated with your customer and confirmed their availability to receive the order, and we will reimburse the entire shipping cost, even if the customer does not receive the order and it is returned to you.')}}</p>
            <div class="mt-6 gap-3 flex sm:flex-row flex-col sm:justify-between">
              <vs-checkbox v-model="doNotShowAgain" class="text-sm">{{ $t("Don't show this message again") }}</vs-checkbox>
              <vs-button @click="addAction()">{{$t('Deliver Again')}}</vs-button>
            </div>
          </div>
          <div class="mt-4" v-else-if="actionType === 'change_phone_number'">
            <div class="flex sm:flex-row flex-col items-center justify-center gap-x-8 gap-y-3">
              <div>
                <p class="font-semibold text-black">Current Phone Number</p>
                <vs-input v-model="rowData.customer.phone" :disabled="true" />
              </div>
              <span class="material-icons sm:hidden block sm:mt-5">expand_more</span>
              <span class="material-icons sm:block hidden sm:mt-5">chevron_right</span>
              <div>
                <p class="font-semibold text-primary">New Phone Number</p>
                <vs-input v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" name="new phone" v-model="rowData.new_phone" />
                <span class="text-danger text-sm" v-show="errors.has('new phone')">{{ errors.first('new phone') }}</span>
              </div>
            </div>
            <div class="mt-8 flex sm:justify-end justify-center">
              <vs-button @click="addAction()" class="m-auto">{{$t('Change Number')}}</vs-button>
            </div>
          </div>
          <div class="mt-4" v-else-if="actionType === 'change_cod'">
            <div class="flex sm:flex-row flex-col items-center justify-center gap-x-8 gap-y-3">
              <div>
                <p class="font-semibold text-black">Current COD EGP</p>
                <vs-input v-model="rowData.cash_amount" :disabled="true" />
              </div>
              <span class="material-icons sm:hidden block sm:mt-5">expand_more</span>
              <span class="material-icons sm:block hidden sm:mt-5">chevron_right</span>
              <div>
                <p class="font-semibold text-primary">New COD EGP</p>
                <vs-input v-validate="'required|decimal'" name="amount" v-model="rowData.new_cash" />
                <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first('amount') }}</span>
              </div>
            </div>
            <div class="mt-8 flex sm:justify-end justify-center">
              <vs-button @click="addAction()">{{$t('Change COD')}}</vs-button>
            </div>
          </div>
        </div>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import { sendRequest } from '../../../http/axios/requestHelper'
import i18nData from '../../../i18n/i18nData'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['actionModal', 'actionType', 'rowData'],
  data () {
    return {
      doNotShowAgain: false
    }
  },
  methods: {
    getAttemptReason () {
      if (this.actionModal === true) {
        return this.rowData.tracking_events.filter(item => item.status === 'delivery_attempted')
      }
    },
    addAction () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const body = {
            action: this.actionType,
            data: this.actionType === 'change_cod' ? {
              cash_amount: Number(this.rowData.new_cash)
            } : {
              phone_number: this.rowData.new_phone
            }
          }
          sendRequest(false, false, this, `api/v1/analytics/merchant/action-center/${this.rowData.id}/`, 'post', body, true,
            (response) => {
              this.$vs.notify({
                color:'success',
                title: i18nData[this.$i18n.locale]['Success'],
                text: response.data.message,
                position: 'top-center'
              })
              if (this.doNotShowAgain === true) {
                localStorage.removeItem('showDeliverAgainMessage')
                localStorage.setItem('showDeliverAgainMessage', false)
              }
              this.$emit('loadData')
              this.$emit('actionModal', false)
            }
          )
        }
      })
    },
    closeModal () {
      this.$emit('actionModal', false)
    }
  },
  components: {
    ShipbluPrompt
  }
}
</script>